<div class="container footer text-muted">
  <div class="row">
    <div class="col">Password Manager MellowoodMedical</div>
    <div class="col text-center"></div>
    <div class="col text-right">
      ({{ "webVault" | i18n }})
      {{ "versionNumber" | i18n : version }}
    </div>
  </div>
</div>
